import React, { ReactElement, useState } from 'react';
import { Modal } from '@material-ui/core';

import { userExperimentsState } from '../../store/experimentState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Snackbar, Alert } from '../toaster';
import { createExperimentFromTemplate } from '../../utils/templateUtils';
import { Experiment, getValidExperimentsByUser } from '../../utils/experimentUtils';
import { userState } from '../../store/authState';
import { tabState } from '../../store/tabState';
import { useNavigate } from 'react-router-dom';
import { CreateExperimentForm, ExperimentInitialFields } from '../common/CreateExperimentForm';

export interface NewExperimentDialogProps {
  show: boolean;
  onClose(): void;
  templateId: number | null;
}

const defaultValues: ExperimentInitialFields = {
  name: '',
  eln: '',
  project_id: '',
  region: '',
  scientist: [],
  target_customer: '',
  technician: [],
};

const CreateTemplateExperimentModal = ({ show, onClose, templateId }: NewExperimentDialogProps): ReactElement => {
  const [showError, setShowError] = useState<boolean>(false);
  const [experimentInitialValues, setExperimentInitialValues] = useState<ExperimentInitialFields>(defaultValues);

  const navigate = useNavigate();

  const user = useRecoilValue(userState);
  const setUserExperiments = useSetRecoilState<Experiment[]>(userExperimentsState);
  const setTabState = useSetRecoilState(tabState);

  const updateExperimentList = () => {
    if (user.attributes.email) {
      getValidExperimentsByUser(user.attributes.email.toLowerCase()).then((res: Experiment[]) => {
        setUserExperiments(res);
      });
    }
  };

  const handleExperimentSubmit = () => {
    const { name, eln, project_id, region, scientist, target_customer, technician } = experimentInitialValues;
    const experimentObj = {
      experiment_name: name,
      template_id: templateId,
      eln,
      project_id,
      region,
      scientist,
      target_customer,
      technician,
    };
    createExperimentFromTemplate(experimentObj)
      .then(() => {
        updateExperimentList();
        onClose();
        setTabState('experiments');
        navigate('/');
      })
      .catch(() => {
        setShowError(true);
      });
  };

  return (
    <>
      <Modal open={show} onClose={onClose} disableAutoFocus disableEnforceFocus>
        <CreateExperimentForm
          handleExperimentSubmit={handleExperimentSubmit}
          fields={experimentInitialValues}
          onClose={onClose}
          updateField={(key, value) => {
            setExperimentInitialValues({
              ...experimentInitialValues,
              [key]: value,
            });
          }}
        />
      </Modal>
      <Snackbar open={showError} autoHideDuration={5000} onClose={() => setShowError(false)}>
        <Alert onClose={() => setShowError(false)} severity="error">
          Something went wrong creating your experiment. Please try again or contact the administrator.
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateTemplateExperimentModal;
