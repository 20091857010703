import ApiUtils from './apiUtils';

export interface FieldBoundary {
  field_data_type: string;
  field_is_required: boolean;
  field_max_value: number;
  field_min_value: number;
  field_name: string;
  field_unit: string;
  id: number;
}

export async function getBoundraryValues(): Promise<Record<string, FieldBoundary>> {
  const url = '/boundary_values';
  const response = await ApiUtils.get(url);
  const responseData: FieldBoundary[] = response.data;

  const boundaryValueObj = responseData.reduce<Record<string, FieldBoundary>>((acc, boundary) => {
    return { ...acc, [boundary.field_name]: boundary };
  }, {});
  return boundaryValueObj;
}
