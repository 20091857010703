import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Button } from '@novozymes-digital/components';
import { Box } from '@material-ui/core';

import { BallastDrawer } from '../common/BallastDrawer';
import { BallastMetaType } from '../../../../services/apiTypes';
import { addBallastMetadata, getBallastMetadata } from '../../../../utils/experimentUtils';
import produce from 'immer';

interface FixedBallastProps {
  numberOfComponents: number;
  disabled?: boolean;
  experimentId: number | null;
}

export const FixedBallast = ({ numberOfComponents, disabled, experimentId }: FixedBallastProps): ReactElement => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [ballastFields, setBallastFields] = useState<BallastMetaType[]>([]);

  useEffect(() => {
    const fetchBallasts = async () => {
      if (experimentId) {
        const res = await getBallastMetadata(experimentId);

        if (res.status === 200 && res.data.length > 0) {
          setBallastFields(res.data);
        } else {
          const ballasts: BallastMetaType[] = [];
          for (let index = 0; index < numberOfComponents; index++) {
            ballasts.push({
              ballast_name: `Ballast type ${index + 1}`,
              ballast_amount_pr_container_g: 1,
              ballast_batch: '',
              ballast_type: '',
            });
          }
          setBallastFields(ballasts);
        }
      }
    };

    if (drawerIsOpen) {
      fetchBallasts();
    }
  }, [drawerIsOpen, experimentId, numberOfComponents]);

  const handleUpdateValue = useCallback(
    (key: keyof BallastMetaType, newValue: string | number, selectedStainTab: number) => {
      const nextState = produce(ballastFields, (draftState) => {
        if (draftState[selectedStainTab]) {
          draftState[selectedStainTab] = { ...draftState[selectedStainTab], [key]: newValue };
        }
      });
      setBallastFields(nextState);
    },
    [ballastFields]
  );

  const handleSaveData = (ballastData: BallastMetaType[]) => {
    if (experimentId) {
      addBallastMetadata(experimentId, ballastData);
    }
  };

  return (
    <>
      <Box display="flex">
        <Button small disabled={disabled} onClick={() => setDrawerIsOpen(true)}>
          Fixed
        </Button>
      </Box>

      <BallastDrawer
        type="fixed"
        isOpen={drawerIsOpen}
        ballastFields={ballastFields}
        handleCloseModal={() => setDrawerIsOpen(false)}
        handleSaveData={handleSaveData}
        handleUpdateValue={handleUpdateValue}
      />
    </>
  );
};
