import React, { ReactElement } from 'react';
import { Button, SelectWithLabel, Close, OutlinedInputWithLabel, colors } from '@novozymes-digital/components';
import { Box, Modal, Card, makeStyles, Typography } from '@material-ui/core';
import { ColumnMapping, ColumnName } from './CSVUpload';
import TooltipComponent from '../Tooltip';
import { IngredientCategoryEnum } from '../../services/apiTypes';

const useStyles = makeStyles(() => ({
  modalRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBody: {
    padding: '1.5rem',
    width: '100%',
    maxWidth: '80rem',
    height: '29.5rem',
    borderRadius: '1rem',
    '&:focus': {
      outline: 'none',
    },
  },
  modalContent: {
    height: 'calc(100% - 7rem)',
    overflowY: 'auto',
    justifyContent: 'space-between',
    margin: '1rem 0',
  },
  modalActions: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeButton: {
    cursor: 'pointer',
  },
  inputLabel: {
    height: '1.5rem',
    color: colors.black80,
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
  },
}));

export type IngredientCategoryOptionsType = {
  label: string;
  value: IngredientCategoryEnum;
  key: IngredientCategoryEnum;
};

interface CsvUploadModalProps {
  setParsedCsv: (value: unknown) => void;
  setSelectedCsv: (value: string) => void;
  csvColumnOptions: string[];
  resetCsvMapping: () => void;
  handleSaveCsvData: () => void;
  columnMapping: ColumnMapping;
  columnMappingErrorCheck: (value: string) => boolean | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateColumnMapping: (e: any, column: string, field: keyof ColumnName) => void;
  csvMappingModalOpen: boolean;
  setCsvMappingModalOpen: (value: boolean) => void;
  ingredientCategoryOptions: IngredientCategoryOptionsType[];
}

export default function CsvUploadModal(props: CsvUploadModalProps): ReactElement {
  const classes = useStyles();
  const {
    setParsedCsv,
    setSelectedCsv,
    csvColumnOptions,
    resetCsvMapping,
    handleSaveCsvData,
    columnMapping,
    updateColumnMapping,
    columnMappingErrorCheck,
    csvMappingModalOpen,
    setCsvMappingModalOpen,
    ingredientCategoryOptions,
  } = props;

  const handleCloseModal = () => {
    setCsvMappingModalOpen(false);
    setParsedCsv(null);
    setSelectedCsv('');
  };

  const getCsvMappingModalContent = (): ReactElement[] => {
    const content: ReactElement[] = [];
    const options = csvColumnOptions.map((option) => ({
      key: option,
      label: option,
      value: option,
    }));
    content.push(
      <Box display="flex" alignItems="center" justifyContent="space-between" margin="0.75rem 1.5rem">
        <Box width="10.5rem" />
        <Box width="10.5rem">
          <Typography className={classes.inputLabel}>Name</Typography>
        </Box>
        <Box width="10.5rem">
          <Typography className={classes.inputLabel}>Batch id/no</Typography>
        </Box>
        <Box width="10.5rem" display="flex">
          <Typography className={classes.inputLabel}>Dilution factor</Typography>
          <TooltipComponent title="Dilution is made before experiment start and dosage/beaker should be the final dosage including dilution factor" />
        </Box>
        <Box width="10.5rem" display="flex">
          <Typography className={classes.inputLabel}>Ingredient Category</Typography>
        </Box>
        <Box width="10.5rem" display="flex">
          <Typography className={classes.inputLabel}>Ingredient Type</Typography>
        </Box>
      </Box>
    );

    Object.keys(columnMapping).forEach((column: string, idx: number): void => {
      content.push(
        <Box key={idx} display="flex" alignItems="center" justifyContent="space-between" margin="0.75rem 1.5rem">
          <Box width="10.5rem">
            {column.charAt(0).toUpperCase() + column.slice(1).replace(/_/g, ' ').replace('mass', ' (g)')}
          </Box>
          <Box width="10.5rem">
            <SelectWithLabel
              value={columnMapping[column].file_column_name}
              options={options}
              error={columnMappingErrorCheck(columnMapping[column].file_column_name)}
              errorMessage="Multiple columns with same name"
              handleChange={(e) => updateColumnMapping(e, column, 'file_column_name')}
            />
          </Box>
          <Box width="10.5rem">
            <OutlinedInputWithLabel
              placeholder="Batch id/no"
              value={columnMapping[column].batch_id}
              handleChange={(e) => updateColumnMapping(e, column, 'batch_id')}
              type="text"
            />
          </Box>
          <Box width="10.5rem">
            <OutlinedInputWithLabel
              placeholder="Dilution Factor"
              value={columnMapping[column].dilution_factor}
              handleChange={(e) => updateColumnMapping(e, column, 'dilution_factor')}
              type="number"
            />
          </Box>
          <Box width="10.5rem">
            <SelectWithLabel
              value={columnMapping[column].ingredient_category}
              options={ingredientCategoryOptions}
              placeholder="Please Select..."
              handleChange={(e) => updateColumnMapping(e, column, 'ingredient_category')}
            />
          </Box>
          <Box width="10.5rem">
            <OutlinedInputWithLabel
              value={columnMapping[column].ingredient_type}
              placeholder="Ingredient Type"
              type="text"
              handleChange={(e) => updateColumnMapping(e, column, 'ingredient_type')}
            />
          </Box>
        </Box>
      );
    });
    return content;
  };

  return (
    <Modal className={classes.modalRoot} open={csvMappingModalOpen} onClose={() => handleCloseModal()}>
      <Card className={classes.modalBody}>
        <Box display="flex" justifyContent="space-between">
          <Box fontSize="1rem" fontWeight="bold" marginLeft="1.5rem" marginTop="1.5rem">
            Select matching columns
          </Box>
          <Close className={classes.closeButton} onClick={() => handleCloseModal()} />
        </Box>

        <Box className={classes.modalContent}>{getCsvMappingModalContent()}</Box>
        <Box className={classes.modalActions}>
          <Button type="secondary" small onClick={() => resetCsvMapping()}>
            Reset
          </Button>
          <Button small onClick={() => handleSaveCsvData()}>
            Save data
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
