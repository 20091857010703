/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState, useEffect } from 'react';
import { makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import { Grid, TableHeaderRow, TableFixedColumns, VirtualTable, Table } from '@devexpress/dx-react-grid-material-ui';
import { colors } from '@novozymes-digital/components';
import { MatchingType } from '../../services/apiTypes';

const ENTER_KEY_CODE = 13;
const ESC_KEY_CODE = 27;

const isColumnEditable = (column: string): boolean => {
  return column === 'order' ? false : true;
};

const Cell = (props: any): ReactElement => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<number | undefined>(undefined);

  const { value, updateCsv, style, column, tableRow, viewMode } = props;

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleInputChange = (event: any) => {
    setCurrentValue(event.target.value);
  };

  const handleKeyPress = (event: any) => {
    if (event.keyCode === ENTER_KEY_CODE && currentValue !== value) {
      setEditMode(false);
      updateCsv(currentValue, column.name, tableRow.rowId);
      setCurrentValue(value);
    } else if (event.keyCode === ESC_KEY_CODE) {
      setEditMode(false);
      setCurrentValue(value);
    }
  };

  return (
    <Table.Cell
      {...props}
      style={{
        borderRight: `1px solid ${colors.dataGrey}`,
        ...style,
      }}
      onClick={() => setEditMode(true)}
    >
      {editMode && isColumnEditable(column.name) && !viewMode ? (
        <>
          <TextField
            value={currentValue}
            onChange={handleInputChange}
            type="number"
            inputProps={{
              style: { fontSize: '0.75rem' },
            }}
            onKeyDown={handleKeyPress}
          />
        </>
      ) : (
        <span>{currentValue}</span>
      )}
    </Table.Cell>
  );
};

const HeaderCell = ({ column, style, ...restProps }: any): ReactElement => (
  <Table.Cell
    {...restProps}
    style={{
      borderRight: `1px solid ${colors.dataGrey}`,
      fontWeight: 'bold',
      ...style,
    }}
  >
    {column.title === 'Powder detergent mass' ? 'Powder detergent' : column.title}
  </Table.Cell>
);

const validateUpdatedData = (updatedObject: any) => {
  const rowSum = Object.keys(updatedObject).reduce((sum, key) => sum + parseFloat(updatedObject[key] || 0), 0);

  if (rowSum > 50) return 'The row has a sum of over the maximum of 50';

  const filtered = Object.keys(updatedObject).filter((el) => updatedObject[el] > 10 || updatedObject[el] < 0);

  if (filtered.length > 0) return 'The value is outside the allowed boundaries (Max = 10, Min = 0)';

  return;
};

interface WashTableProps {
  mappedCsv: any;
  matching?: MatchingType[] | undefined;
  setMappedCsv?: (mappedCsv: any) => void;
  setCsvValidationError?: (value: string) => void;
  viewMode?: boolean;
}

interface Column {
  name: string;
  title: string;
}

interface TableColumnExtensions {
  columnName: string;
  width: number | string;
}

const useStyles = makeStyles(() => ({
  groupLabel: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
  },
  paper: {
    padding: '24px',
    borderRadius: '8px',
  },
}));

const WashTable = (props: WashTableProps): ReactElement => {
  const classes = useStyles();
  const [columns, setColumns] = useState<Column[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [tableColumnExtensions] = useState<TableColumnExtensions[]>([{ columnName: 'order', width: 100 }]);

  const { mappedCsv, matching, setMappedCsv, setCsvValidationError, viewMode } = props;

  useEffect(() => {
    const mappedCsvWithWash = mappedCsv.map((row: any, idx: number) => ({
      order: idx + 1,
      ...row,
    }));
    setRows(mappedCsvWithWash);

    const mappedColumns = Object.keys(mappedCsvWithWash[0]).map((column) => ({
      name: column,
      title: getFormattedColumn(
        column,
        matching?.find((item) => item.component === column)
      ),
    }));
    setColumns(mappedColumns);
  }, [mappedCsv, matching]);

  const getFormattedColumn = (column: any, matching_column: MatchingType | undefined) => {
    const formatted = column.charAt(0).toUpperCase() + column.slice(1).replace(/_/g, ' ');
    const file_column_name = matching_column ? matching_column.file_column_name : '';
    if (formatted.includes('Order')) {
      return formatted;
    }

    return file_column_name == '' ? formatted.replace(' mass', ' (g)') : file_column_name;
  };

  const updateCsv = (updatedValue: number, column: 'string', row: number) => {
    const updatedMappedCsv = [...mappedCsv];
    updatedMappedCsv[row][column] = updatedValue;
    const updatedObject = updatedMappedCsv[row];

    const validationError = validateUpdatedData(updatedObject);

    if (validationError) {
      setCsvValidationError && setCsvValidationError(validationError);
    } else {
      if (setMappedCsv) {
        setMappedCsv(updatedMappedCsv);
      }
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.groupLabel}>Wash Table</Typography>
      <Grid rows={rows} columns={columns}>
        <VirtualTable
          columnExtensions={tableColumnExtensions}
          cellComponent={(props: any) => <Cell {...props} updateCsv={updateCsv} viewMode={viewMode} />}
        />
        <TableHeaderRow cellComponent={HeaderCell} />
        <TableFixedColumns leftColumns={['order']} />
      </Grid>
    </Paper>
  );
};

export default WashTable;
