import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { DropDown, DropUp, OutlinedInputWithLabel, SelectWithLabel } from '@novozymes-digital/components';
import { Box, makeStyles, Typography, Theme } from '@material-ui/core';
import {
  MeasurementInstrumentsType,
  StainSensitivityClassEnum,
  SwatchMetaType,
  SwatchSideEnum,
} from '../../../../services/apiTypes';
import { getMeasurementInstruments } from '../../../../utils/experimentUtils';

const useStyles = makeStyles((theme: Theme) => ({
  modalContent: {
    height: 'calc(100% - 7rem)',
    overflowY: 'auto',
    justifyContent: 'space-between',
    margin: '2rem',
  },

  /* New */
  advancedWrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid #E6E6E6',
  },
  advancedToggle: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  groupLabel: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '8px',
  },
  moreFieldsLabel: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
  },
  inputWrapper: {
    width: '14rem',
    height: '80px',
    marginRight: theme.spacing(2),
  },
}));

interface StainFormProps {
  fields: SwatchMetaType;
  handleUpdateValue: (key: keyof SwatchMetaType, newValue: string | number | string[]) => void;
  type: 'fixed' | 'variable';
}

export function StainForm({ fields, handleUpdateValue, type }: StainFormProps): ReactElement {
  const classes = useStyles();
  const [showCollapsed, setShowCollapsed] = useState(false);
  const [measurementInstruments, setMeasurementInstruments] = useState<MeasurementInstrumentsType[]>([]);

  const stainSensitivityClassOptions = useMemo(
    () => [
      {
        label: StainSensitivityClassEnum.Amylase,
        value: StainSensitivityClassEnum.Amylase,
        key: StainSensitivityClassEnum.Amylase,
      },
      {
        label: StainSensitivityClassEnum.Cellulase,
        value: StainSensitivityClassEnum.Cellulase,
        key: StainSensitivityClassEnum.Cellulase,
      },
      {
        label: StainSensitivityClassEnum.Lipase,
        value: StainSensitivityClassEnum.Lipase,
        key: StainSensitivityClassEnum.Lipase,
      },
      {
        label: StainSensitivityClassEnum.Mannanase,
        value: StainSensitivityClassEnum.Mannanase,
        key: StainSensitivityClassEnum.Mannanase,
      },
      {
        label: StainSensitivityClassEnum.Protease,
        value: StainSensitivityClassEnum.Protease,
        key: StainSensitivityClassEnum.Protease,
      },
      {
        label: StainSensitivityClassEnum.DNAse,
        value: StainSensitivityClassEnum.DNAse,
        key: StainSensitivityClassEnum.DNAse,
      },
      {
        label: StainSensitivityClassEnum.Detergency,
        value: StainSensitivityClassEnum.Detergency,
        key: StainSensitivityClassEnum.Detergency,
      },
      {
        label: StainSensitivityClassEnum.Bleachable,
        value: StainSensitivityClassEnum.Bleachable,
        key: StainSensitivityClassEnum.Bleachable,
      },
    ],
    []
  );

  const swatchSideOptions = useMemo(
    () => [
      {
        label: `${SwatchSideEnum.Size5x5} cm`,
        value: SwatchSideEnum.Size5x5,
        key: SwatchSideEnum.Size5x5,
      },
      {
        label: `${SwatchSideEnum.Size5x10} cm`,
        value: SwatchSideEnum.Size5x10,
        key: SwatchSideEnum.Size5x10,
      },
      {
        label: `${SwatchSideEnum.Size6x6} cm`,
        value: SwatchSideEnum.Size6x6,
        key: SwatchSideEnum.Size6x6,
      },
    ],
    []
  );

  useEffect(() => {
    const fetchMeasurementInstruments = async () => {
      try {
        const res = await getMeasurementInstruments();
        setMeasurementInstruments(res.data);
      } catch (error) {
        setMeasurementInstruments([]);
      }
    };

    fetchMeasurementInstruments();
  }, []);

  return (
    <Box className={classes.modalContent}>
      <Typography className={classes.groupLabel}>Mandatory fields</Typography>
      <Box mb={2}>
        <Box display="inline-flex" pb={2}>
          <Box className={classes.inputWrapper}>
            <OutlinedInputWithLabel
              label={'Stain type'}
              value={fields.stain_types || ''}
              type="text"
              handleChange={(event) => {
                handleUpdateValue('stain_types', event.target.value);
              }}
              disabled={false}
            />
          </Box>
          {type === 'fixed' && (
            <Box width="15rem">
              <OutlinedInputWithLabel
                label={'Pieces of swatches pr. container'}
                value={fields.pieces_of_swatches_pr_container || ''}
                type="number"
                handleChange={(event) => {
                  handleUpdateValue('pieces_of_swatches_pr_container', event.target.value);
                }}
                disabled={false}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box display="inline-flex" pb={2}>
        <Box className={classes.inputWrapper}>
          <OutlinedInputWithLabel
            label={'Measurement method'}
            value={fields.measurement_method || ''}
            type="text"
            handleChange={(event) => {
              handleUpdateValue('measurement_method', event.target.value);
            }}
          />
        </Box>
        <Box className={classes.inputWrapper}>
          <SelectWithLabel
            label={'Measurement equipment'}
            value={fields.measurement_equipment || []}
            placeholder="Please select an option"
            multiple
            options={measurementInstruments.map((instrument) => ({
              label: instrument.instrument_label,
              value: instrument.instrument_value,
              key: instrument.id,
            }))}
            handleChange={(e) => {
              handleUpdateValue('measurement_equipment', e.target.value as string[]);
            }}
            disabled={false}
          />
        </Box>
      </Box>
      <Box mb={2}>
        <Box display="inline-flex" pb={2}>
          <Box className={classes.inputWrapper}>
            <OutlinedInputWithLabel
              label={'Swatch sides measured'}
              value={fields.swatch_sides_measured || ''}
              type="number"
              handleChange={(event) => {
                handleUpdateValue('swatch_sides_measured', event.target.value);
              }}
              disabled={false}
            />
          </Box>
        </Box>
      </Box>

      <Typography className={classes.groupLabel}>Optional fields</Typography>
      <Box mb={2}>
        <Box display="inline-flex" pb={2}>
          <Box className={classes.inputWrapper}>
            <OutlinedInputWithLabel
              label={'Stain batch'}
              value={fields.stain_batch || ''}
              type="text"
              handleChange={(event) => {
                handleUpdateValue('stain_batch', event.target.value);
              }}
              disabled={false}
            />
          </Box>
          <Box className={classes.inputWrapper}>
            <SelectWithLabel
              label={'Stain sensitivity class'}
              placeholder="Please select an option"
              value={fields.stain_sensitivity_class || ''}
              options={stainSensitivityClassOptions}
              handleChange={(event) => {
                handleUpdateValue('stain_sensitivity_class', event.target.value as StainSensitivityClassEnum);
              }}
              disabled={false}
            />
          </Box>
        </Box>
      </Box>
      <Box mb={2}>
        <Box display="inline-flex" pb={2}>
          <Box className={classes.inputWrapper}>
            <OutlinedInputWithLabel
              label={'Substrate'}
              value={fields.substrate || ''}
              type="text"
              handleChange={(event) => {
                handleUpdateValue('substrate', event.target.value);
              }}
              disabled={false}
            />
          </Box>
          <Box className={classes.inputWrapper}>
            <SelectWithLabel
              label={'Swatch size'}
              placeholder="Please select an option"
              value={fields.swatch_size_cm || ''}
              options={swatchSideOptions}
              handleChange={(event) => {
                handleUpdateValue('swatch_size_cm', event.target.value as SwatchSideEnum);
              }}
              disabled={false}
            />
          </Box>
          <Box className={classes.inputWrapper}>
            <OutlinedInputWithLabel
              label={'Textile type'}
              value={fields.textile_type || ''}
              type="text"
              handleChange={(event) => {
                handleUpdateValue('textile_type', event.target.value);
              }}
              disabled={false}
            />
          </Box>
        </Box>
      </Box>

      <Box className={classes.advancedWrapper} pt={1} pb={2}>
        <Box className={classes.advancedToggle} onClick={() => setShowCollapsed(!showCollapsed)} pb={2}>
          <Box display="flex" alignItems="center">
            <Typography className={classes.moreFieldsLabel}> More fields</Typography>
            {showCollapsed ? <DropUp /> : <DropDown />}
          </Box>
        </Box>
        {showCollapsed && (
          <>
            <Box mb={2}>
              <Typography className={classes.groupLabel}>Swatch</Typography>
              <Box display="inline-flex" pb={2}>
                <Box className={classes.inputWrapper}>
                  <OutlinedInputWithLabel
                    label={'Swatch manufacturer'}
                    value={fields.swatch_manufacturer || ''}
                    type="text"
                    handleChange={(event) => {
                      handleUpdateValue('swatch_manufacturer', event.target.value);
                    }}
                    disabled={false}
                  />
                </Box>
              </Box>
            </Box>
            <Box mb={2}>
              <Typography className={classes.groupLabel}>Substrate</Typography>
              <Box display="inline-flex" pb={2}>
                <Box className={classes.inputWrapper}>
                  <OutlinedInputWithLabel
                    label={'Substrate batch'}
                    value={fields.substrate_batch || ''}
                    type="text"
                    handleChange={(event) => {
                      handleUpdateValue('substrate_batch', event.target.value);
                    }}
                    disabled={false}
                  />
                </Box>
                <Box className={classes.inputWrapper}>
                  <OutlinedInputWithLabel
                    label={'Substrate load'}
                    value={fields.substrate_load || ''}
                    type="number"
                    handleChange={(event) => {
                      handleUpdateValue('substrate_load', event.target.value);
                    }}
                    disabled={false}
                  />
                </Box>
                <Box className={classes.inputWrapper}>
                  <OutlinedInputWithLabel
                    label={'Substrate load unit'}
                    value={fields.substrate_load_unit || ''}
                    type="text"
                    handleChange={(event) => {
                      handleUpdateValue('substrate_load_unit', event.target.value);
                    }}
                    disabled={false}
                  />
                </Box>
              </Box>
            </Box>
            <Box mb={2}>
              <Typography className={classes.groupLabel}>Drying</Typography>
              <Box display="inline-flex" pb={2}>
                <Box className={classes.inputWrapper}>
                  <OutlinedInputWithLabel
                    adornment={'min'}
                    label={'Drying time'}
                    value={fields.drying_time_min || ''}
                    type="number"
                    handleChange={(event) => {
                      handleUpdateValue('drying_time_min', event.target.value);
                    }}
                    disabled={false}
                  />
                </Box>
                <Box className={classes.inputWrapper}>
                  <OutlinedInputWithLabel
                    adornment={'°C'}
                    label={'Drying temperature'}
                    value={fields.drying_temperature_c || ''}
                    type="number"
                    handleChange={(event) => {
                      handleUpdateValue('drying_temperature_c', event.target.value);
                    }}
                    disabled={false}
                  />
                </Box>
                <Box className={classes.inputWrapper}>
                  <OutlinedInputWithLabel
                    label={'Drying location'}
                    value={fields.drying_location || ''}
                    type="text"
                    handleChange={(event) => {
                      handleUpdateValue('drying_location', event.target.value);
                    }}
                    disabled={false}
                  />
                </Box>
                <Box className={classes.inputWrapper}>
                  <OutlinedInputWithLabel
                    label={'Drying method'}
                    value={fields.drying_method || ''}
                    type="text"
                    handleChange={(event) => {
                      handleUpdateValue('drying_method', event.target.value);
                    }}
                    disabled={false}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
