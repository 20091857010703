/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Close } from '@novozymes-digital/components';
import { read, utils } from 'xlsx';
import Papa from 'papaparse';

import { Box } from '@material-ui/core';

import CsvUploadStainTypesModal from './CsvUploadStainTypesModal';
import { SwatchDesignType, SwatchMetaType } from '../../../../services/apiTypes';
import TooltipComponent from '../../../Tooltip';
import { StainDrawer } from '../common/StainDrawer';
import { addSwatchDesign, addSwatchMetadata, Experiment, getSwatchMetadata } from '../../../../utils/experimentUtils';
import produce from 'immer';

export interface ColumnMapping {
  [key: string]: string;
}

export interface SwatchMapping {
  [key: string]: number;
}

const convertToArrayOfObjects = (data: any) => {
  const keys = data.shift();
  const mappedData = data.map((row: any) => {
    return keys.reduce(function (obj: any, key: any, i: any) {
      obj[key] = row[i];
      return obj;
    }, {});
  });
  return mappedData;
};

export type MappedCSVData = Array<Record<string, string | number>>;

interface VariableTypeProps {
  numberOfComponents: number;
  disabled?: boolean;
  handleChangeSwatches: ((swatches: SwatchDesignType[]) => void) | undefined;
  swatchDesignData: SwatchDesignType[];
  setErrorMessage: any;
  experimentId: number | null;
  fileName?: string;
  handleCustomValueUpdate: (experimentKey: keyof Experiment, newValue: number | string | boolean) => void;
}

export const VariableType = ({
  numberOfComponents,
  handleChangeSwatches,
  disabled,
  setErrorMessage,
  experimentId,
  swatchDesignData,
  fileName = '',
  handleCustomValueUpdate,
}: VariableTypeProps): ReactElement => {
  const [selectedCsv, setSelectedCsv] = useState<string>(fileName);
  const [parsedCsv, setParsedCsv] = useState<any>(null);
  const [csvMappingModalOpen, setCsvMappingModalOpen] = useState<boolean>(false);
  const [csvColumnOptions, setCsvColumnOptions] = useState<string[]>([]);
  const [columnMapping, setColumnMapping] = useState<ColumnMapping>({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [swatchFields, setSwatchFields] = useState<SwatchMetaType[]>([]);
  const [swatchDesign, setSwatchDesign] = useState<SwatchDesignType[]>([]);

  const handleRemoveCsv = () => {
    handleCustomValueUpdate('stain_file_name', '');
    setSelectedCsv('');
    setParsedCsv(null);
    setColumnMapping({});
    setCsvColumnOptions([]);
    updateSwatchDesign([]);
    removeSwatchMetadata();
  };

  const resetCsvMapping = () => {
    const columnMapObject: ColumnMapping = {};
    for (let i = 0; i < numberOfComponents; i++) {
      const key = `swatch_${i + 1}`;
      columnMapObject[key] = '';
    }
    setColumnMapping(columnMapObject);
  };

  const validateImportedCsv = (results: any[]) => {
    if (results.length > 193) {
      handleRemoveCsv();
      return 'The CSV file exceeds the maximum number of rows (Max=192 rows)';
    } else if ((results.length && results[0].length) < numberOfComponents) {
      handleRemoveCsv();
      return 'The stain number is higher than the amount of columns in the CSV';
    }
    return '';
  };

  const handleUploadedData = (data: any[], file: any) => {
    resetCsvMapping();
    const filteredResults = data.filter((row: any[]) => row[0] !== '');

    const validationError = validateImportedCsv(filteredResults);
    if (validationError) {
      setErrorMessage(validationError);
    } else {
      const parsed = convertToArrayOfObjects(filteredResults);
      setSelectedCsv(file.name);
      handleCustomValueUpdate('stain_file_name', file.name);
      setParsedCsv(parsed);
      setCsvMappingModalOpen(true);
      const columnOptions = Object.keys(parsed[0]);
      setCsvColumnOptions(columnOptions);
    }
  };

  const handleUploadedCsv = (input: any[]) => {
    const file = input.length ? input[0] : null;
    const splitFileName = file.name.split('.');
    const fileType = splitFileName[splitFileName.length - 1];

    if (fileType === 'xlsx') {
      const reader = new FileReader();
      reader.onload = (evt: any) => {
        const bstr = evt.target.result;
        const wb = read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data: any[] = utils.sheet_to_json(ws, { defval: 0 });
        if (data.length) {
          const columns = Object.keys(data[0]);
          const rows = data.map(function (obj: any) {
            return Object.keys(obj).map(function (key) {
              return obj[key];
            });
          });
          const formattedData = [columns, ...rows];
          handleUploadedData(formattedData, file);
        } else {
          setErrorMessage("The uploaded file doesn't contain any data");
          handleRemoveCsv();
        }
      };
      reader.readAsBinaryString(file);
    } else if (fileType === 'csv') {
      Papa.parse(file, {
        complete: (results: any) => {
          if (results.data.length && results.data[0][0] !== '' && results.data[1][0] !== '') {
            handleUploadedData(results.data, file);
          } else {
            setErrorMessage("The uploaded file doesn't contain any data");
            handleRemoveCsv();
          }
        },
      });
    } else {
      setErrorMessage('The file format is not supported, only CSV and XLSX files are supported');
    }
  };

  const handleSaveCsvData = () => {
    const columns = Object.keys(columnMapping).map((column) => columnMapping[column]);

    /* New Mapping */
    const swatchDesign: SwatchDesignType[] = parsedCsv.map((item: SwatchMapping, index: number) => {
      return {
        wash_order_id: index,
        wash_design: columns.map((key) => ({
          stain_id: key,
          swatch_count: item[key],
        })),
      };
    });

    const swatches: SwatchMetaType[] = columns.map((column) => ({
      stain_id: column,
      pieces_of_swatches_pr_container: 1,
      stain_batch: '',
      stain_types: column,
      swatch_sides_measured: 1,
      measurement_equipment: [],
      measurement_method: '',
    }));

    setSwatchFields(swatches);
    setSwatchDesign(swatchDesign);
    setCsvMappingModalOpen(false);
    setOpenDrawer(true);
  };

  const columnMappingErrorCheck = (column: string): boolean => {
    const filtered = Object.keys(columnMapping).filter((c: string) => columnMapping[c] === column && columnMapping[c]);
    const isError = filtered.length > 1;
    return isError;
  };

  const updateColumnMapping = (e: React.ChangeEvent<HTMLInputElement>, column: string) => {
    const updatedMapping = { ...columnMapping };

    updatedMapping[column] = e.target.value;

    setColumnMapping(updatedMapping);
  };

  const handleSaveData = (swatchData: SwatchMetaType[]) => {
    if (experimentId) {
      addSwatchMetadata(experimentId, swatchData);
      if (swatchDesignData.length === 0) {
        updateSwatchDesign(swatchDesign);
      }
    }
  };

  const updateSwatchDesign = (swatchDesign: SwatchDesignType[]) => {
    if (experimentId) {
      addSwatchDesign(experimentId, swatchDesign);
      handleChangeSwatches?.(swatchDesign);
    }
  };

  const removeSwatchMetadata = () => {
    if (experimentId) {
      addSwatchMetadata(experimentId, []);
    }
  };

  const handleUpdateValue = useCallback(
    (key: keyof SwatchMetaType, newValue: string | number | string[], selectedStainTab: number) => {
      const nextState = produce(swatchFields, (draftState) => {
        if (draftState[selectedStainTab]) {
          draftState[selectedStainTab] = { ...draftState[selectedStainTab], [key]: newValue };
        }
      });
      setSwatchFields(nextState);
    },
    [swatchFields]
  );

  useEffect(() => {
    const fetchSwatches = async () => {
      if (experimentId) {
        const res = await getSwatchMetadata(experimentId);
        if (res.status === 200 && res.data.length > 0) {
          setSwatchFields(res.data);
        }
      }
    };

    if (openDrawer) {
      fetchSwatches();
    }
  }, [openDrawer, experimentId, numberOfComponents]);

  return (
    <>
      {swatchDesignData.length > 0 ? (
        <>
          <Button
            small
            disabled={disabled}
            onClick={() => {
              setOpenDrawer(true);
            }}
          >
            Variable
          </Button>
          <Box fontSize="0.875rem" marginLeft="0.5rem" display="flex" alignItems="center">
            Selected file: {selectedCsv}{' '}
            <Button small type="tertiary">
              <Close onClick={() => handleRemoveCsv()} />
            </Button>
          </Box>
        </>
      ) : (
        <Dropzone
          disabled={disabled}
          //accept=".csv, .xlsx, application/vnd.ms-excel, text/csv"
          onDrop={(uploadedFile) => handleUploadedCsv(uploadedFile)}
        >
          {({ getRootProps, getInputProps }) => (
            <Box display="flex" {...getRootProps()}>
              <Button small disabled={disabled}>
                Variable
                <input {...getInputProps()} />
              </Button>
              <TooltipComponent title="Supports excel (.xls/.xlsx) and .csv files.  File should contain data in a tabular format, with no merged cells (if excel file).  Number of columns in table should be at least as many as the number of components selected. Number of rows in table should be as many as the number of washes to be tested (total number of washes, which includes conditions, and respective replicates)" />
            </Box>
          )}
        </Dropzone>
      )}

      <CsvUploadStainTypesModal
        csvColumnOptions={csvColumnOptions}
        resetCsvMapping={resetCsvMapping}
        handleSaveCsvData={handleSaveCsvData}
        columnMapping={columnMapping}
        columnMappingErrorCheck={columnMappingErrorCheck}
        updateColumnMapping={updateColumnMapping}
        csvMappingModalOpen={csvMappingModalOpen}
        handleCloseModal={() => {
          setCsvMappingModalOpen(false);
          setParsedCsv(null);
          setSelectedCsv('');
          handleCustomValueUpdate('stain_file_name', '');
        }}
      />

      <StainDrawer
        type="variable"
        isOpen={openDrawer}
        handleCloseModal={() => setOpenDrawer(false)}
        handleSaveData={handleSaveData}
        swatchFields={swatchFields}
        handleUpdateValue={handleUpdateValue}
      />
    </>
  );
};
