import React, { ReactElement, useState } from 'react';
import { Modal } from '@material-ui/core';
import { userExperimentsState } from '../../store/experimentState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userState } from '../../store/authState';
import { CreateExperiment, createExperiment, Experiment, getValidExperimentsByUser } from '../../utils/experimentUtils';
import { Snackbar, Alert } from '../toaster';
import { CreateExperimentForm, ExperimentInitialFields } from '../common/CreateExperimentForm';

export interface NewExperimentDialogProps {
  show: boolean;
  onClose(): void;
}

const defaultValues: ExperimentInitialFields = {
  name: '',
  eln: '',
  project_id: '',
  region: '',
  scientist: [],
  target_customer: '',
  technician: [],
};

const CreateExperimentDialog = ({ show, onClose }: NewExperimentDialogProps): ReactElement => {
  const [showError, setShowError] = useState<boolean>(false);
  const [experimentInitialValues, setExperimentInitialValues] = useState<ExperimentInitialFields>(defaultValues);

  const user = useRecoilValue(userState);

  const setUserExperiments = useSetRecoilState<Experiment[]>(userExperimentsState);

  const updateExperimentList = () => {
    if (user.attributes.email) {
      getValidExperimentsByUser(user.attributes.email.toLowerCase()).then((res: Experiment[]) => {
        setUserExperiments(res);
      });
    }
  };

  const handleExperimentSubmit = () => {
    const currentTimestamp = new Date();
    const experimentObj: CreateExperiment = {
      created_by: user.attributes.email.toLowerCase(),
      valid_from: currentTimestamp.toUTCString(),
      ...experimentInitialValues,
    };
    createExperiment(experimentObj)
      .then(() => {
        updateExperimentList();
        setExperimentInitialValues(defaultValues);
        onClose();
      })
      .catch(() => {
        setShowError(true);
      });
  };

  return (
    <>
      <Modal open={show} onClose={onClose} disableAutoFocus disableEnforceFocus>
        <CreateExperimentForm
          handleExperimentSubmit={handleExperimentSubmit}
          fields={experimentInitialValues}
          onClose={onClose}
          updateField={(key, value) => {
            setExperimentInitialValues({
              ...experimentInitialValues,
              [key]: value,
            });
          }}
        />
      </Modal>
      <Snackbar open={showError} autoHideDuration={5000} onClose={() => setShowError(false)}>
        <Alert onClose={() => setShowError(false)} severity="error">
          Something went wrong creating your experiment. Please try again or contact the administrator.
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateExperimentDialog;
