export type MatchingType = {
  component: string;
  file_column_name: string;
  batch_id?: string;
  dilution_factor?: number;
  ingredient_category?: IngredientCategoryEnum;
  ingredient_type?: string;
};

export enum ConfidentialityEnum {
  sharable = 'sharable',
  not_sharable = 'not_sharable',
  never_sharable = 'never_sharable',
}

export enum IngredientCategoryEnum {
  enzyme = 'enzyme',
  detergent = 'detergent',
  surfactant = 'surfactant',
  component = 'component',
}

export type SwatchMetaType = {
  stain_id: string;
  drying_location?: string;
  drying_method?: string;
  drying_temperature_c?: number;
  drying_time_min?: number;
  measurement_equipment: string[];
  measurement_method: string;
  measurement_timepoint?: string;
  pieces_of_swatches_pr_container: number;
  stain_batch: string;
  stain_sensitivity_class?: string;
  stain_types: string;
  substrate?: string;
  substrate_batch?: string;
  substrate_load?: number;
  substrate_load_unit?: string;
  swatch_manufacturer?: string;
  swatch_sides_measured: number;
  swatch_size_cm?: string;
  textile_type?: string;
};

export type BallastMetaType = {
  ballast_name: string;
  ballast_type: string;
  ballast_batch: string;
  ballast_amount_pr_container_g: number;

  ballast_pre_treatment_description?: string;
  soil_ballast_batch_id?: string;
  soil_ballast_name?: string;
  soil_ballast_pieces_pr_container?: number;
  soil_ballast_pre_treatment_description?: string;
};

export type WashDesignType = {
  stain_id: string;
  swatch_count: number;
};

export type BallastWashDesignType = {
  ballast_name: string;
  ballast_amount_pr_container_g: number;
};

export type SwatchDesignType = {
  wash_order_id: number;
  wash_design: WashDesignType[];
};

export type SwatchOutputDesignType = {
  experiment_id: number;
  swatch_design: SwatchDesignType[];
};

export type BallastDesignType = {
  wash_order_id: number;
  wash_design: BallastWashDesignType[];
};

export type BallastOutputDesignType = {
  experiment_id: number;
  ballast_design: BallastDesignType[];
};

export enum MeasurementMethodEnum {
  DigiEye = 'DigiEye',
  other = 'other',
}

export enum StainSensitivityClassEnum {
  Amylase = 'Amylase',
  Cellulase = 'Cellulase',
  Lipase = 'Lipase',
  Mannanase = 'Mannanase',
  Protease = 'Protease',
  DNAse = 'DNAse',
  Detergency = 'Detergency',
  Bleachable = 'Bleachable',
}

export enum SwatchSideEnum {
  Size5x5 = '5x5',
  Size5x10 = '5x10',
  Size6x6 = '6x6',
}

export type MeasurementInstrumentsType = {
  id: number;
  instrument_label: string;
  instrument_value: string;
};
