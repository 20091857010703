import React, { ReactElement, useState, useEffect, useMemo, useCallback } from 'react';
import { Button, FolderAdd, Left, More, Share } from '@novozymes-digital/components';
import { Box, makeStyles, Theme } from '@material-ui/core';
import ExperimentForm from '../components/Experiment/ExperimentForm';
import { FieldBoundary, getBoundraryValues } from '../services/getBoundaryValues';
import {
  deleteExperiment,
  Experiment,
  getFullExperiment,
  getHardnessParameters,
  HardnessParametersType,
  restoreExperiment,
  saveExperiment,
  sendToAtom,
  unSendToAtom,
} from '../utils/experimentUtils';
import { Snackbar, Alert } from '../components/toaster';
import LoadingSpinner from '../components/LoadingSpinner';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../components/layout/Page';
import ButtonWithIcon from '../components/ButtonWithIcon';
import Title from '../components/Title';
import moment from 'moment';
import { AxiosError } from 'axios';
import PopupMenu from '../components/PopupMenu';
import DeleteModal from '../components/DeleteModal';
import DuplicateExperimentModal from '../components/Experiment/DuplicateExperimentModal';
import ConfirmationModal from '../components/ConfirmationModal';
import CreateTemplateModal from '../components/Template/CreateTemplateModal';
import { getTimingCalculationString } from '../utils/timingCalculationUtils';
import { validateExperiment } from '../components/Experiment/ExperimentValueValidation';
import WashTable from '../components/Experiment/WashTable';
import { debounce } from 'lodash';
import SortAndFilterWashes from '../components/Experiment/SortAndFilterWashes';
import SaveConflictModal from '../components/SaveConflictModal';
import getExperimentMenu from '../components/Experiment/getExperimentMenu';
import getDeletedExperimentMenu from '../components/Experiment/getDeletedExperimentMenu';
import { Template } from '../utils/templateUtils';
import { getCalculatedWaterConsumption } from '../utils/waterConsumptionUtils';
import SwatchTable from '../components/Experiment/StainTypes/variable/SwatchTable';
import { BallastDesignType, SwatchDesignType } from '../services/apiTypes';
import BallastTable from '../components/Experiment/Ballast/variable/BallastTable';

const useStyle = makeStyles((theme: Theme) => ({
  moreIconButton: {
    paddingLeft: '3px',
    paddingRight: '3px',
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
      cursor: 'pointer',
    },
  },
  lastSavedTitleText: { fontSize: '0.9rem', fontStyle: 'italic' },
}));

const ExperimentComponent = (): ReactElement => {
  const classes = useStyle();
  const [experimentErrorMessage, setExperimentErrorMessage] = useState<string>('');
  const [experimentSuccessMessage, setExperimentSuccessMessage] = useState<string>('');
  const [boundaryValues, setBoundaryValues] = useState<Record<string, FieldBoundary> | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [experiment, setExperiment] = useState<undefined | Experiment>(undefined);
  const [experimentId, setExperimentId] = useState<string>('');
  const navigate = useNavigate();
  const { experiment_id } = useParams();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [experimentForDuplication, setExperimentForDuplication] = useState<Experiment | undefined>(undefined);
  const popupMenuOpen = Boolean(anchorEl);
  const [sendToAtomModalOpen, setSendToAtomModalOpen] = useState<boolean>(false);
  const [createTemplateModalOpen, setCreateTemplateModalOpen] = useState<boolean>(false);
  const [saveConflictModalOpen, setSaveConflictModalOpen] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [totalTiming, setTotalTiming] = useState('');
  const [hardnessParameters, setHardnessParameters] = useState<HardnessParametersType | undefined>(undefined);
  const [totalRinseVolume, setTotalRinseVolume] = useState(0);
  const [swatchData, setSwatchData] = useState<SwatchDesignType[]>([]);
  const [ballastData, setBallastData] = useState<BallastDesignType[]>([]);

  const experimentStateSend: Record<string, string> = {
    draft: 'Send to A-TOM',
    sent: 'Unsend',
    executed: 'Executed',
  };

  useEffect(() => {
    if (experiment_id) setExperimentId(experiment_id);
  }, [experiment_id]);

  const fetchExperiment = useCallback((experimentId: string) => {
    getFullExperiment(experimentId)
      .then((response) => {
        setExperiment(response.data);
        setLoading(false);
        setIsOwner(response.data.access_level === 'owner');
        setViewMode(!!response.data.valid_till || response.data.experiment_state !== 'draft');
        setIsDeleted(!!response.data.valid_till);
        setBallastData(response.data.ballast_design);
        setSwatchData(response.data.swatch_design);
      })
      .catch(({ response }) => {
        if (response && response.status === 403) {
          setExperimentErrorMessage("You don't have access to this experiment");
        } else {
          setExperimentErrorMessage('Something went wrong. Try again!');
        }

        setLoading(false);
      });
  }, []);

  const fetchHardnessParameters = useCallback(() => {
    getHardnessParameters()
      .then((response) => {
        setHardnessParameters(response.data);
      })
      .catch(() => {
        setExperimentErrorMessage('Something went wrong. Try again!');
      });
  }, []);

  useEffect(() => {
    getBoundraryValues().then((boundariesObj) => {
      setBoundaryValues(boundariesObj);
    });

    setLoading(true);

    if (experimentId) {
      fetchExperiment(experimentId);
      fetchHardnessParameters();
    }
  }, [experimentId, fetchExperiment, fetchHardnessParameters]);

  const handleSendToAtom = () => {
    if (experiment) {
      sendToAtom(experimentId)
        .then(() => {
          setSendToAtomModalOpen(false);

          setExperimentSuccessMessage('Successfully sent the experiment to A-TOM');

          fetchExperiment(experimentId);
        })
        .catch(() => {
          setSendToAtomModalOpen(false);

          setExperimentErrorMessage('Something went wrong when sending the experiment to A-TOM');
        });
    } else {
      setExperimentErrorMessage('Failed to send experiment to Atom');
    }
  };

  const handleUnSendToAtom = () => {
    if (experiment) {
      unSendToAtom(experimentId)
        .then(() => {
          setSendToAtomModalOpen(false);

          setExperimentSuccessMessage('Successfully cancel the experiment to A-TOM');

          fetchExperiment(experimentId);
        })
        .catch(() => {
          setSendToAtomModalOpen(false);

          setExperimentErrorMessage('Something went wrong when canceling the experiment to A-TOM');
        });
    } else {
      setExperimentErrorMessage('Failed to cancel experiment to Atom');
    }
  };

  const handleActionToAtom = () => {
    if (experiment) {
      if (experiment.experiment_state === 'draft') {
        handleSendToAtom();
      } else if (experiment.experiment_state === 'sent') {
        handleUnSendToAtom();
      }
    }
  };

  useEffect(() => {
    if (experiment) {
      getTimingCalculationString(experiment).then(({ estimatedTime, total_rinse_volume }) => {
        setTotalTiming(estimatedTime);
        setTotalRinseVolume(total_rinse_volume);
      });
    }
  }, [experiment]);

  const getConfirmationMessage = (): ReactElement => {
    if (experiment) {
      if (experiment.experiment_state === 'draft') {
        const totalWaterConsumption = experiment ? getCalculatedWaterConsumption(experiment, totalRinseVolume) : 0;
        return (
          <>
            <Box marginBottom="1rem">Are you sure you want to send the experiment to A-TOM?</Box>
            <li>The estimated run time is {totalTiming}.</li>
            <li>The total number of washes are {experiment?.number_of_washes}.</li>
            <li>The total water consumption is {totalWaterConsumption} L.</li>
          </>
        );
      } else if (experiment.experiment_state === 'sent') {
        return <Box marginBottom="1rem">Are you sure you want to cancel the experiment to A-TOM?</Box>;
      }
    }
    return <></>;
  };

  const handleSaveExperiment = (experiment: Experiment, isAutoSave?: boolean) => {
    if (experiment.experiment_state === 'sent') return;

    const validationErrors = validateExperiment(experiment, boundaryValues ?? {});
    if (!Object.values(validationErrors).includes(true)) {
      if (experiment.washes && experiment.washes.length) {
        experiment.number_of_washes = experiment.washes.length;
      } else if (experiment.number_of_washes) {
        experiment.number_of_washes = experiment.number_of_washes;
      }

      if (experiment.detergent_type === 'powder' || experiment.detergent_dosing === 'fixed_dose') {
        experiment.detergent_number = 1;
        experiment.detergent_dosing = 'fixed_dose';
      }

      if (experiment.detergent_dosing === 'fixed_dose') {
        experiment.washes = [];
      }

      saveExperiment(experiment)
        .then(() => {
          if (!isAutoSave) {
            setExperimentSuccessMessage('Your experiment has been saved.');
          }
        })
        .catch((e: AxiosError) => {
          if (e && e.response && e.response.status === 409) {
            setSaveConflictModalOpen(true);
          } else {
            setExperimentErrorMessage(
              'Something went wrong saving your experiment. Please try again or contact the administrator.'
            );
          }
        });
    } else {
      if (!isAutoSave) {
        setExperimentErrorMessage('Please fix the validation errors before saving');
      }
    }
  };

  const debouncedSave = debounce((experiment: Experiment) => {
    handleSaveExperiment(experiment, true);
  }, 1000);

  const handleExperimentUpdate = useCallback(
    (exp: Experiment) => {
      setExperiment(exp);
      debouncedSave(exp);
    },
    [debouncedSave]
  );

  const [nowTimestamp, setNowTimestamp] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setNowTimestamp(Date.now());
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [setNowTimestamp]);

  const lastSavedAtString = useMemo(() => {
    if (!experiment?.last_modified) {
      return '';
    }
    const secondsSinceSave = Math.abs(moment.utc(experiment?.last_modified).diff(nowTimestamp, 'seconds'));
    const A_DAY_IN_SECONDS = 60 * 60 * 24;
    if (secondsSinceSave < A_DAY_IN_SECONDS) {
      return `Last saved ${moment.utc(experiment?.last_modified).fromNow()}`;
    }
    return `Last saved ${moment.utc(experiment?.last_modified).format('YYYY-MM-DD, HH:mm:ss')}`;
  }, [nowTimestamp, experiment]);

  const handleCloseError = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setExperimentErrorMessage('');
  };

  const handleCloseSuccess = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setExperimentSuccessMessage('');
  };

  const onDuplicateClick = () => {
    setExperimentForDuplication(experiment);
  };

  const onCreateTemplateClick = () => {
    setCreateTemplateModalOpen(true);
  };
  const onDeleteClick = () => {
    setDeleteModalOpen(true);
    setAnchorEl(null);
  };

  const onSharingClick = () => {
    navigate(`/sharing/${experiment?.id}`);
  };

  const onRestoreClick = () => {
    if (experiment && experiment.id) {
      restoreExperiment(experiment.id)
        .then(() => {
          fetchExperiment(experiment.id?.toString() ?? '');
          setExperimentSuccessMessage('Successfully restored experiment');
        })
        .catch(() => {
          setExperimentErrorMessage('Failed to restore experiment');
        });
    }
  };

  const popupItems = isDeleted
    ? getDeletedExperimentMenu({ isOwner, onDuplicateClick, onRestoreClick })
    : getExperimentMenu({
        isOwner,
        onDuplicateClick,
        onCreateTemplateClick,
        onDeleteClick,
        onSharingClick,
      });

  const handlePopupClick = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget;
    setTimeout(() => {
      setAnchorEl(anchorEl ? null : target);
    });
  };

  const handleDelete = () => {
    setDeleteModalOpen(false);
    experiment &&
      experiment.id &&
      deleteExperiment(experiment.id).then(() => {
        navigate(`/`);
      });
  };

  const handleBackClick = () => {
    navigate(`/`);
  };

  const subHeaderLeft = (
    <>
      <ButtonWithIcon
        id="btn-back-to-experiments"
        title="Back to experiments"
        icon={<Left />}
        onClick={handleBackClick}
      />
    </>
  );
  const subHeaderRight = (
    <>
      <ButtonWithIcon
        id="btn-share"
        title={experiment === undefined ? '' : experimentStateSend[experiment.experiment_state]}
        icon={<Share />}
        onClick={() => {
          setSendToAtomModalOpen(true);
        }}
        disabled={experiment === undefined ? true : experiment.experiment_state === 'executed'}
      />
      <ButtonWithIcon
        id="btn-save"
        title="Save"
        icon={<FolderAdd />}
        onClick={() => {
          if (experiment) {
            handleSaveExperiment(experiment);
          }
        }}
      />
      <Box className={classes.moreIconButton} onClick={handlePopupClick}>
        <More />
      </Box>
    </>
  );

  const title = <Title experiment={experiment} lastSaved={lastSavedAtString} />;

  const validationErrors = useMemo(() => {
    if (!experiment) {
      return {};
    }
    return validateExperiment(experiment, boundaryValues ?? {});
  }, [boundaryValues, experiment]);

  const mappedCSVData = useMemo(() => {
    if (!experiment) {
      return [];
    }
    return SortAndFilterWashes(experiment.washes);
  }, [experiment]);

  const handleMappedCSVUpdate = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (mappedCsvData: any) => {
      const washes =
        mappedCsvData &&
        mappedCsvData.map((obj: Record<string, string>, idx: number) => {
          return { wash_order_id: idx + 1, ...obj };
        });
      if (experiment) {
        handleExperimentUpdate({
          ...experiment,
          washes: washes,
        });
      }
    },
    [experiment, handleExperimentUpdate]
  );

  const handleSaveConflictAction = (action: string) => {
    if (action === 'view') {
      setViewMode(true);
      setSaveConflictModalOpen(false);
    } else if (action === 'refresh') {
      window.location.reload();
    }
  };

  const handleChangeSwatches = useCallback((swatches: SwatchDesignType[]) => {
    setSwatchData(swatches);
  }, []);

  const handleChangeBallasts = useCallback((ballasts: BallastDesignType[]) => {
    setBallastData(ballasts);
  }, []);

  return (
    <Page title={title} subHeaderContentLeft={subHeaderLeft} subHeaderContentRight={subHeaderRight}>
      <Box display="flex" flexDirection="row" py={6}>
        <Box flex="1" width="50%" style={{ padding: '0 8px' }}>
          {experiment && boundaryValues && (
            <ExperimentForm
              experiment={experiment}
              boundaryValues={boundaryValues}
              hardnessParameters={hardnessParameters}
              onExperimentUpdate={handleExperimentUpdate as (experiment: Template | Experiment) => void}
              validationErrors={validationErrors}
              viewMode={viewMode}
              setExperimentErrorMessage={setExperimentErrorMessage}
              handleChangeSwatches={handleChangeSwatches}
              swatchData={swatchData}
              handleChangeBallasts={handleChangeBallasts}
              ballastData={ballastData}
            />
          )}
        </Box>
        <Box flex="1" width="50%" style={{ padding: '0 8px' }}>
          {experiment && mappedCSVData && mappedCSVData.length && experiment.detergent_dosing !== 'fixed_dose' ? (
            <WashTable
              mappedCsv={mappedCSVData}
              matching={experiment.matching}
              setMappedCsv={handleMappedCSVUpdate}
              setCsvValidationError={setExperimentErrorMessage}
              viewMode={viewMode}
            />
          ) : null}
          {experiment && experiment.id ? (
            <SwatchTable
              viewMode={viewMode}
              experimentId={experiment.id}
              handleChangeSwatches={handleChangeSwatches}
              swatchData={swatchData}
            />
          ) : null}
          {experiment && experiment.id ? (
            <BallastTable
              viewMode={viewMode}
              experimentId={experiment.id}
              handleChangeBallasts={handleChangeBallasts}
              ballastData={ballastData}
            />
          ) : null}
        </Box>
      </Box>
      <>
        {loading ? <LoadingSpinner /> : null}
        {!loading && !experiment ? (
          <>
            <Box textAlign="center" marginTop="2rem">
              You do not have access to the experiment you are trying to load.
            </Box>
            <Box textAlign="center">
              <a href="/">
                <Button type="secondary">Go to the home page</Button>
              </a>
            </Box>
          </>
        ) : null}
        <Snackbar open={!!experimentErrorMessage} autoHideDuration={6000} onClose={handleCloseError}>
          <Alert onClose={handleCloseError} severity="error">
            {experimentErrorMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={!!experimentSuccessMessage} autoHideDuration={6000} onClose={handleCloseSuccess}>
          <Alert onClose={handleCloseSuccess} severity="success">
            {experimentSuccessMessage}
          </Alert>
        </Snackbar>
      </>
      <PopupMenu anchorRef={anchorEl} popupOpen={popupMenuOpen} setAnchorEl={setAnchorEl} items={popupItems} />
      <DeleteModal
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        handleDelete={handleDelete}
        message="Are you sure you want to delete this experiment?"
      />
      <DuplicateExperimentModal
        originalExperiment={experimentForDuplication}
        onClose={() => setExperimentForDuplication(undefined)}
      />
      <ConfirmationModal
        open={sendToAtomModalOpen}
        onClose={() => setSendToAtomModalOpen(false)}
        action={handleActionToAtom}
        message={getConfirmationMessage()}
      />
      <CreateTemplateModal
        show={createTemplateModalOpen}
        onClose={() => setCreateTemplateModalOpen(false)}
        experimentId={experiment?.id || null}
      />
      <SaveConflictModal
        open={saveConflictModalOpen}
        onClose={() => setSaveConflictModalOpen(false)}
        onAction={handleSaveConflictAction}
      />
    </Page>
  );
};

export default ExperimentComponent;
