import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Button, Close } from '@novozymes-digital/components';
import { Box, makeStyles, Drawer } from '@material-ui/core';
import { NzTabs, NzTab } from '@novozymes-digital/dezymer-core';
import { BallastForm } from './BallastForm';
import { BallastMetaType } from '../../../../services/apiTypes';

const useStyles = makeStyles(() => ({
  drawerBody: {
    padding: '24px',
    minWidth: '60rem',
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeButton: {
    cursor: 'pointer',
  },
}));

interface BallastDrawerProps {
  type: 'fixed' | 'variable';
  handleSaveData: (ballastData: BallastMetaType[]) => void;
  handleCloseModal: () => void;
  isOpen: boolean;
  ballastFields: BallastMetaType[];
  handleUpdateValue: (key: keyof BallastMetaType, newValue: string | number, selectedStainTab: number) => void;
}

export function BallastDrawer({
  handleUpdateValue,
  handleCloseModal,
  handleSaveData,
  isOpen,
  ballastFields,
  type,
}: BallastDrawerProps): ReactElement {
  const classes = useStyles();

  const [selectedStainTab, setSelectedStainTab] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeTab = (event: any, newValue: any): void => {
    setSelectedStainTab(newValue);
  };

  const updateValue = useCallback(
    (key: keyof BallastMetaType, newValue: string | number) => {
      handleUpdateValue(key, newValue, selectedStainTab);
    },
    [handleUpdateValue, selectedStainTab]
  );

  const disableButton = useMemo(() => {
    return ballastFields.some((fields) => {
      if (
        !fields.ballast_name ||
        !fields.ballast_type ||
        !fields.ballast_batch ||
        !fields.ballast_amount_pr_container_g
      ) {
        return true;
      }
    });
  }, [ballastFields]);

  return (
    <Drawer
      anchor={'right'}
      open={isOpen}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
        handleCloseModal();
      }}
    >
      <Box className={classes.drawerBody}>
        <Box display="flex" justifyContent="space-between">
          <Box fontSize="1rem" fontWeight="bold" marginLeft="1.5rem">
            {type === 'fixed' ? 'Fixed ballast types' : 'Variable ballast types'}
          </Box>
          <Close className={classes.closeButton} onClick={() => handleCloseModal()} />
        </Box>

        <NzTabs value={selectedStainTab} onChange={handleChangeTab}>
          {ballastFields.map((ballast, index) => (
            <NzTab key={index} label={ballast.ballast_name} value={index} />
          ))}
        </NzTabs>

        {ballastFields[selectedStainTab] && (
          <BallastForm fields={ballastFields[selectedStainTab]} handleUpdateValue={updateValue} type={type} />
        )}

        <Box className={classes.modalActions}>
          <Button
            small
            onClick={() => {
              handleCloseModal();
              handleSaveData(ballastFields);
            }}
            disabled={disableButton}
          >
            Save data
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
