/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { Button, SelectWithLabel, Close, colors } from '@novozymes-digital/components';
import { Box, Modal, Card, makeStyles, Typography } from '@material-ui/core';
import { ColumnMapping } from './VariableBallast';

const useStyles = makeStyles(() => ({
  modalRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBody: {
    padding: '1.5rem',
    width: '100%',
    maxWidth: '30rem',
    height: '29.5rem',
    borderRadius: '1rem',
    '&:focus': {
      outline: 'none',
    },
  },
  modalContent: {
    height: 'calc(100% - 7rem)',
    overflowY: 'auto',
    justifyContent: 'space-between',
    margin: '1rem 0',
  },
  modalActions: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeButton: {
    cursor: 'pointer',
  },
  inputLabel: {
    height: '1.5rem',
    color: colors.black80,
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
  },
}));

interface CsvUploadBallastModalProps {
  csvColumnOptions: string[];
  resetCsvMapping: () => void;
  handleSaveCsvData: () => void;
  handleCloseModal: () => void;
  columnMapping: ColumnMapping;
  columnMappingErrorCheck: (value: string) => boolean | undefined;
  updateColumnMapping: (e: any, column: string) => void;
  csvMappingModalOpen: boolean;
}

export default function CsvUploadBallastModal(props: CsvUploadBallastModalProps): ReactElement {
  const classes = useStyles();
  const {
    csvColumnOptions,
    resetCsvMapping,
    handleSaveCsvData,
    columnMapping,
    updateColumnMapping,
    columnMappingErrorCheck,
    csvMappingModalOpen,
  } = props;

  const getCsvMappingModalContent = (): ReactElement[] => {
    const content: any[] = [];
    const options = csvColumnOptions.map((option) => ({
      key: option,
      label: option,
      value: option,
    }));
    content.push(
      <Box display="flex" alignItems="center" margin="0.75rem 1.5rem">
        <Box width="10.5rem" />
        <Box width="10.5rem">
          <Typography className={classes.inputLabel}>Name</Typography>
        </Box>
      </Box>
    );

    Object.keys(columnMapping).forEach((column: string, idx: number): void => {
      content.push(
        <Box key={idx} display="flex" alignItems="center" margin="0.75rem 1.5rem">
          <Box width="10.5rem">
            {column.charAt(0).toUpperCase() + column.slice(1).replace(/_/g, ' ').replace('mass', ' (g)')}
          </Box>
          <Box width="10.5rem">
            <SelectWithLabel
              value={columnMapping[column]}
              options={options}
              error={columnMappingErrorCheck(columnMapping[column])}
              errorMessage="Multiple columns with same name"
              handleChange={(e) => updateColumnMapping(e, column)}
            />
          </Box>
        </Box>
      );
    });
    return content;
  };

  return (
    <>
      <Modal className={classes.modalRoot} open={csvMappingModalOpen} onClose={() => props.handleCloseModal()}>
        <Card className={classes.modalBody}>
          <Box display="flex" justifyContent="space-between">
            <Box fontSize="1rem" fontWeight="bold" marginLeft="1.5rem" marginTop="1.5rem">
              Select matching columns
            </Box>
            <Close className={classes.closeButton} onClick={() => props.handleCloseModal()} />
          </Box>

          <Box className={classes.modalContent}>{getCsvMappingModalContent()}</Box>
          <Box className={classes.modalActions}>
            <Button type="secondary" small onClick={() => resetCsvMapping()}>
              Reset
            </Button>
            <Button small onClick={() => handleSaveCsvData()}>
              Save data
            </Button>
          </Box>
        </Card>
      </Modal>
    </>
  );
}
