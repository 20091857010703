import React, { ReactElement, useState } from 'react';
import {
  withStyles,
  createStyles,
  makeStyles,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Box,
} from '@material-ui/core';
import { colors, Button } from '@novozymes-digital/components';
import ConfirmationModal from '../ConfirmationModal';
import { changeExperimentOwner, ChangeOwnerData, unshareExperiment } from '../../utils/sharingUtils';
import { Snackbar, Alert } from '../toaster';

export interface Collaborator {
  valid_from: string;
  username: string;
  id: number;
  valid_to: string;
  access_level: string;
  experiment_id: string;
}

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      fontWeight: 'bold',
      fontSize: '0.875rem',
      cursor: 'pointer',
    },
    body: {
      fontSize: '0.875rem',
      border: 'none',
    },
  })
)(TableCell);

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      height: '4.5rem',
      '&:nth-of-type(even)': {
        backgroundColor: colors.bg2,
      },
    },
  })
)(TableRow);

interface RowProps {
  collaborator: Collaborator;
  getCollaboratorList: (id: string) => void;
  setErrorOpen: (value: boolean) => void;
  isOwner: boolean;
  setShowSharingPageSuccessMessage: () => void;
  setSharingPageSuccessMessage: (message: string) => void;
  currentUserUsername: string;
  experimentId: string;
}

const Row = (props: RowProps) => {
  const [stopShareConfirmOpen, setStopShareConfirmOpen] = useState(false);
  const [makeOwnerConfirmOpen, setMakeOwnerConfirmOpen] = useState(false);
  const {
    collaborator,
    getCollaboratorList,
    setErrorOpen,
    isOwner,
    setShowSharingPageSuccessMessage,
    setSharingPageSuccessMessage,
    currentUserUsername,
    experimentId,
  } = props;
  const stopSharingMessage = `Are you sure you want to stop sharing this experiment with ${collaborator.username}`;
  const makeOwnerMessage = `Are you sure you want to make ${collaborator.username} the owner of the experiment?`;

  const handleStopSharing = () => {
    unshareExperiment({
      username: collaborator.username,
      experiment_id: collaborator.experiment_id,
    })
      .then(() => {
        getCollaboratorList(collaborator.experiment_id);
        setShowSharingPageSuccessMessage();
        setSharingPageSuccessMessage('Successfully unshared experiment');
        setStopShareConfirmOpen(false);
      })
      .catch(() => {
        setStopShareConfirmOpen(false);
        setErrorOpen(true);
      });
  };

  const handleMakeOwner = () => {
    const newOwner = collaborator.username;

    if (newOwner.length) {
      const changeOwnerData: ChangeOwnerData = {
        old_owner: currentUserUsername,
        new_owner: newOwner,
        experiment_id: experimentId,
      };
      changeExperimentOwner(changeOwnerData)
        .then(() => {
          getCollaboratorList(experimentId);
          setMakeOwnerConfirmOpen(false);
        })
        .catch(() => {
          setErrorOpen(true);
        });
    }
  };

  const showSharingActions = isOwner && collaborator.access_level !== 'owner';

  return (
    <>
      <StyledTableRow>
        <StyledTableCell component="th" scope="row">
          {collaborator.username}
        </StyledTableCell>
        <StyledTableCell>{collaborator.access_level}</StyledTableCell>
        <StyledTableCell align="right">
          {showSharingActions && (
            <>
              <Button
                small
                type="secondary"
                disabled={!isOwner}
                onClick={() => {
                  if (isOwner) setMakeOwnerConfirmOpen(true);
                }}
              >
                Transfer ownership
              </Button>
              <Button
                small
                type="secondary"
                disabled={!isOwner}
                onClick={() => {
                  if (isOwner) setStopShareConfirmOpen(true);
                }}
              >
                Stop sharing
              </Button>
            </>
          )}
        </StyledTableCell>
      </StyledTableRow>
      <ConfirmationModal
        message={stopSharingMessage}
        open={stopShareConfirmOpen}
        onClose={() => setStopShareConfirmOpen(false)}
        action={() => handleStopSharing()}
      />
      <ConfirmationModal
        message={makeOwnerMessage}
        open={makeOwnerConfirmOpen}
        onClose={() => setMakeOwnerConfirmOpen(false)}
        action={handleMakeOwner}
      />
    </>
  );
};

const useStyles = makeStyles({
  root: {},
  table: {
    boxShadow: 'none',
  },
  header: {
    borderBottom: `2px solid ${colors.dataGrey}`,
  },
  optionsButton: {
    cursor: 'pointer',
  },
  tableHeadContent: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface SharingTableProps {
  collaborators: Collaborator[];
  getCollaboratorList: (id: string) => void;
  isOwner: boolean;
  setShowSharingPageSuccessMessage: () => void;
  setSharingPageSuccessMessage: (message: string) => void;
  currentUserUsername: string;
  experimentId: string;
}

const SharingTable = (props: SharingTableProps): ReactElement => {
  const classes = useStyles();
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const {
    collaborators,
    getCollaboratorList,
    isOwner,
    setShowSharingPageSuccessMessage,
    setSharingPageSuccessMessage,
    experimentId,
    currentUserUsername,
  } = props;

  return (
    <>
      <TableContainer className={classes.root}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead className={classes.header}>
            <TableRow>
              <StyledTableCell>
                <Box className={classes.tableHeadContent}>Mail</Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box className={classes.tableHeadContent}>Role</Box>
              </StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {collaborators.length
              ? collaborators.map((c: Collaborator, idx: number) => (
                  <Row
                    key={idx}
                    collaborator={c}
                    getCollaboratorList={getCollaboratorList}
                    setErrorOpen={setErrorOpen}
                    isOwner={isOwner}
                    setShowSharingPageSuccessMessage={setShowSharingPageSuccessMessage}
                    setSharingPageSuccessMessage={setSharingPageSuccessMessage}
                    currentUserUsername={currentUserUsername}
                    experimentId={experimentId}
                  />
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          Something went wrong. please try again or contact and administrator.
        </Alert>
      </Snackbar>
    </>
  );
};

export default SharingTable;
