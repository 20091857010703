/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Close } from '@novozymes-digital/components';
import { read, utils } from 'xlsx';
import Papa from 'papaparse';
import TooltipComponent from '../Tooltip';
import { Box } from '@material-ui/core';
import CsvUploadModal, { IngredientCategoryOptionsType } from './CsvUploadModal';
import { DetergentType } from './ExperimentForm';
import { IngredientCategoryEnum, MatchingType } from '../../services/apiTypes';
import { Experiment } from '../../utils/experimentUtils';

export interface ColumnName {
  batch_id?: string;
  file_column_name: string;
  dilution_factor?: number;
  ingredient_category?: IngredientCategoryEnum;
  ingredient_type?: string;
}

export interface ColumnMapping {
  [key: string]: ColumnName;
}

const convertToArrayOfObjects = (data: any) => {
  const keys = data.shift();
  const mappedData = data.map((row: any) => {
    return keys.reduce(function (obj: any, key: any, i: any) {
      obj[key] = row[i];
      return obj;
    }, {});
  });
  return mappedData;
};

export type MappedCSVData = Array<Record<string, string | number>>;

interface CSVUploadProps {
  numberOfComponents: number;
  detergentType: DetergentType;
  disabled?: boolean;
  setMappedCsvData: (mappedCsvData?: MappedCSVData, matching?: MatchingType[]) => void;
  setErrorMessage: any;
  fileName?: string;
  handleCustomValueUpdate: (experimentKey: keyof Experiment, newValue: number | string | boolean) => void;
  ingredientCategoryOptions: IngredientCategoryOptionsType[];
}

const CSVUpload = ({
  numberOfComponents,
  detergentType,
  setMappedCsvData,
  disabled,
  setErrorMessage,
  fileName = '',
  handleCustomValueUpdate,
  ingredientCategoryOptions,
}: CSVUploadProps): ReactElement => {
  const [selectedCsv, setSelectedCsv] = useState<string>(fileName);
  const [parsedCsv, setParsedCsv] = useState<any>(null);
  const [csvMappingModalOpen, setCsvMappingModalOpen] = useState<boolean>(false);
  const [csvColumnOptions, setCsvColumnOptions] = useState<string[]>([]);
  const [columnMapping, setColumnMapping] = useState<ColumnMapping>({});

  const handleRemoveCsv = () => {
    handleCustomValueUpdate('detergent_file_name', '');
    setSelectedCsv('');
    setParsedCsv(null);
    setColumnMapping({});
    setMappedCsvData(undefined, undefined);
    setCsvColumnOptions([]);
  };

  const resetCsvMapping = () => {
    const columnMapObject: ColumnMapping = {};
    for (let i = 0; i < numberOfComponents; i++) {
      const key = detergentType === 'powder' ? 'powder_detergent_mass' : `component_${i + 1}_mass`;
      columnMapObject[key] = { file_column_name: '' };
    }
    setColumnMapping(columnMapObject);
  };

  const validateImportedCsv = (results: any[]) => {
    if (results.length > 193) {
      handleRemoveCsv();
      return 'The CSV file exceeds the maximum number of rows (Max=192 rows)';
    } else if ((results.length && results[0].length) < numberOfComponents) {
      handleRemoveCsv();
      return 'The detergent number is higher than the amount of columns in the CSV';
    }
    return '';
  };

  const handleUploadedData = (data: any[], file: any) => {
    resetCsvMapping();
    const filteredResults = data.filter((row: any[]) => row[0] !== '');

    const validationError = validateImportedCsv(filteredResults);
    if (validationError) {
      setErrorMessage(validationError);
    } else {
      const parsed = convertToArrayOfObjects(filteredResults);
      setSelectedCsv(file.name);
      handleCustomValueUpdate('detergent_file_name', file.name);
      setParsedCsv(parsed);
      setCsvMappingModalOpen(true);
      const columnOptions = Object.keys(parsed[0]);
      setCsvColumnOptions(columnOptions);
    }
  };

  const handleUploadedCsv = (input: any[]) => {
    const file = input.length ? input[0] : null;
    const splitFileName = file.name.split('.');
    const fileType = splitFileName[splitFileName.length - 1];

    if (fileType === 'xlsx') {
      const reader = new FileReader();
      reader.onload = (evt: any) => {
        const bstr = evt.target.result;
        const wb = read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data: any[] = utils.sheet_to_json(ws, { defval: 0 });
        if (data.length) {
          const columns = Object.keys(data[0]);
          const rows = data.map(function (obj: any) {
            return Object.keys(obj).map(function (key) {
              return obj[key];
            });
          });
          const formattedData = [columns, ...rows];
          handleUploadedData(formattedData, file);
        } else {
          setErrorMessage("The uploaded file doesn't contain any data");
          handleRemoveCsv();
        }
      };
      reader.readAsBinaryString(file);
    } else if (fileType === 'csv') {
      Papa.parse(file, {
        complete: (results: any) => {
          if (results.data.length && results.data[0][0] !== '' && results.data[1][0] !== '') {
            handleUploadedData(results.data, file);
          } else {
            setErrorMessage("The uploaded file doesn't contain any data");
            handleRemoveCsv();
          }
        },
      });
    } else {
      setErrorMessage('The file format is not supported, only CSV and XLSX files are supported');
    }
  };

  const handleSaveCsvData = () => {
    const propsToKeep = Object.keys(columnMapping).map((prop) => prop);
    const mappedData = parsedCsv.map((item: any) => {
      const obj: any = {};
      for (const prop of propsToKeep) {
        const key = columnMapping[prop];
        obj[prop] = item[key.file_column_name];
      }
      return obj;
    });

    const validateMappedColums = (data: any[]): string | void => {
      const rowSums = data.map((obj: any) => {
        return Object.keys(obj).reduce((sum, key) => sum + parseFloat(obj[key] || 0), 0);
      });

      if (rowSums.some((el: number) => el > 50)) {
        return 'There are rows that have a sum of over the maximum of 50';
      } else if (rowSums.some((el: number) => isNaN(el))) {
        return "There are entries that aren't numbers in the CSV";
      }

      let isOutsideBoundary = false;
      for (let i = 0; i < data.length; i++) {
        const obj: any = data[i];
        const filtered = Object.keys(obj).filter((el) => obj[el] > 10 || obj[el] < 0);
        isOutsideBoundary = filtered.length > 0;
        if (isOutsideBoundary) break;
      }

      if (isOutsideBoundary) {
        return 'There are entries that are above or below the component boundaries (Max = 10, Min = 0)';
      }
      return;
    };

    const error = validateMappedColums(mappedData);
    setCsvMappingModalOpen(false);
    if (error) {
      setParsedCsv(null);
      handleCustomValueUpdate('detergent_file_name', '');
      setSelectedCsv('');
      setErrorMessage(error);
    } else {
      const matching: MatchingType[] = propsToKeep.map((column) => ({
        batch_id: columnMapping[column].batch_id,
        component: column,
        file_column_name: columnMapping[column].file_column_name,
        dilution_factor: columnMapping[column].dilution_factor,
        ingredient_category: columnMapping[column].ingredient_category,
        ingredient_type: columnMapping[column].ingredient_type,
      }));

      setMappedCsvData(mappedData, matching);
    }
  };

  const columnMappingErrorCheck = (column: string): boolean => {
    const filtered = Object.keys(columnMapping).filter(
      (c: string) => columnMapping[c].file_column_name === column && columnMapping[c].file_column_name
    );
    const isError = filtered.length > 1;
    return isError;
  };

  const updateColumnMapping = (e: React.ChangeEvent<HTMLInputElement>, column: string, field: keyof ColumnName) => {
    const updatedMapping = { ...columnMapping };

    if (field === 'dilution_factor') {
      const dilutionFactor = parseFloat(e.target.value);

      updatedMapping[column][field] = dilutionFactor;
    } else {
      updatedMapping[column][field] = e.target.value as IngredientCategoryEnum;
    }

    setColumnMapping(updatedMapping);
  };

  return (
    <>
      <Dropzone
        disabled={disabled}
        //accept=".csv, .xlsx, application/vnd.ms-excel, text/csv"
        onDrop={(uploadedFile) => handleUploadedCsv(uploadedFile)}
      >
        {({ getRootProps, getInputProps }) => (
          <Box display="flex" {...getRootProps()}>
            <Button small disabled={disabled}>
              Import detergent dosing
              <input {...getInputProps()} />
            </Button>
            <TooltipComponent title="Supports excel (.xls/.xlsx) and .csv files.  File should contain data in a tabular format, with no merged cells (if excel file).  Number of columns in table should be at least as many as the number of components selected. Number of rows in table should be as many as the number of washes to be tested (total number of washes, which includes conditions, and respective replicates)" />
          </Box>
        )}
      </Dropzone>
      {selectedCsv && (
        <Box fontSize="0.875rem" marginLeft="0.5rem" display="flex" alignItems="center">
          Selected file: {selectedCsv} <Close onClick={() => handleRemoveCsv()} />
        </Box>
      )}
      <CsvUploadModal
        setParsedCsv={setParsedCsv}
        setSelectedCsv={setSelectedCsv}
        csvColumnOptions={csvColumnOptions}
        resetCsvMapping={resetCsvMapping}
        handleSaveCsvData={handleSaveCsvData}
        columnMapping={columnMapping}
        columnMappingErrorCheck={columnMappingErrorCheck}
        updateColumnMapping={updateColumnMapping}
        csvMappingModalOpen={csvMappingModalOpen}
        setCsvMappingModalOpen={setCsvMappingModalOpen}
        ingredientCategoryOptions={ingredientCategoryOptions}
      />
    </>
  );
};

export default CSVUpload;
